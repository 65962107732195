import './scripts/common';
import {onFind} from '@elements/init-modules-in-scope';

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

// Todo: Add page specific JS here e.g:
import * as lightbox from '@elements/lightbox';
lightbox.init({
        plugins: ['zoom', 'video'],
        zoom: true,
        video: true
    }
);

import * as landingpageNav from './scripts/landingpage-nav';
landingpageNav.init();

import * as imageSlider from './scripts/image-slider';
imageSlider.init();

import {init} from '@elements/anchor-nav/bootstrap-5'
init({
        renderItem: function (text, anchor) {
            return '<li class="anchor-nav__item nav-item"><a class="nav-link landingpage-navbar__item js-smoothscroll__link" data-scroll-offset="200" href="#' + anchor + '">' + text + '</a></li>';
        },
        smoothScroll: true,
        scrollSpy: true,
        scrollSpyTarget: '.js-anchor-nav',
        scrollSpyOffset: 0,
    },
    {
        list: '.js-anchor-nav__list',
        item: '.js-anchor-nav__item'
    }
);