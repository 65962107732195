import {onFind} from "@elements/init-modules-in-scope";
import {removeClass, addClass, hasClass, findIn, findAllIn, closest, on} from '@elements/dom-utils';

export function init () {
    onFind('.js-landingpage-nav', nav => {
        if(window.matchMedia("(max-width: 1199px)").matches) {
            let toggle = findIn('.js-landingpage-nav__toggle', nav);
            let overlay = findIn('.js-landingpage-nav__overlay', nav);

            on('click', (e) => {
                if(hasClass('is-active', toggle)) {
                    removeClass('is-active', overlay);
                    removeClass('is-active', toggle);
                }else{
                    addClass('is-active', overlay);
                    addClass('is-active', toggle);
                }
            }, toggle);

            /*timeout for anchor nav close*/
            setTimeout(() => {
                let smoothScrollLinks = findAllIn('.js-smoothscroll__link', overlay);
                if(smoothScrollLinks){
                    smoothScrollLinks.map(link => {
                        on('click', (e) => {
                            removeClass('is-active', overlay);
                            removeClass('is-active', toggle);
                        }, link);
                    });
                }
            }, 2000);

        }
    });
}